<template>
<span></span>
</template>

<script>
import axios from 'axios'

export default function DirectusCall(url, callback) {

    async function directusCall(url, callback) {
        delete axios.defaults["xsrfCookieName"];
        delete axios.defaults["xsrfHeaderName"];
        delete axios.defaults.headers.common["X-Requested-With"];
        axios.get('https://koordynatorzy.directus.app' + url, {
            responseType: 'json',
            withCredentials: true,
        }).then(response => {
              callback(response.data, response.data.meta);
        })
    }

    directusCall(url, callback);
}
</script>

<style scoped>

</style>
