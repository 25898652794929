<template>
  <UkraineComponent v-if="showUkr"></UkraineComponent>
  <div v-if="!showUkr">
    <div class="grid grid-cols-12">
      <div class="col-span-3" style="max-height: 100vh; overflow-y: scroll;">
        <div class="search py-5 px-5">
          <input type="text" class="jp-input px-5 py-2" v-model="search" placeholder="Szukaj..."/>
        </div>

        <div class="wrapper px-5">
          <div class="heading schools-h">
            <h2 @click="show[0] = !show[0];" class="cursor-pointer font-medium">Szkoły</h2>
          </div>

          <div class="cont" :class="(show[0]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(szkoly)" :key="'item_' + key">
              <ArticleComponent @click="selected(item);" :item="item"></ArticleComponent>
            </template>
          </div>
        </div>
        <div class="wrapper px-5 mt-2">
          <div class="heading pomniki-h">
            <h2 @click="show[1] = !show[1];" class="cursor-pointer font-medium">Pomniki</h2>
          </div>

          <div class="cont" :class="(show[1]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(pomniki)" :key="'item_' + key">
              <ArticleComponent @click="selected(item)" :item="item"></ArticleComponent>
            </template>
          </div>
        </div>
        <div class="wrapper px-5 mt-2">
          <div class="heading honorowi-h">
            <h2 @click="show[2] = !show[2];" class="cursor-pointer font-medium">Honorowi obywatele</h2>
          </div>

          <div class="cont" :class="(show[2]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(honorowi)" :key="'item_' + key">
              <ArticleComponent @click="selected(item)" :item="item"></ArticleComponent>
            </template>
          </div>
        </div>
        <div class="wrapper px-5 mt-2">
          <div class="heading deby-h">
            <h2 @click="show[3] = !show[3];" class="cursor-pointer font-medium">Parki & pomniki przyrody</h2>
          </div>

          <div class="cont" :class="(show[3]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(parki)" :key="'item_' + key">
              <ArticleComponent @click="selected(item)" :item="item"></ArticleComponent>
            </template>
          </div>
        </div>
        <div class="wrapper px-5 mt-2">
          <div class="heading osiedla-h">
            <h2 @click="show[4] = !show[4];" class="cursor-pointer font-medium">Osiedla & ulice</h2>
          </div>

          <div class="cont" :class="(show[4]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(osiedla)" :key="'item_' + key">
              <ArticleComponent @click="selected(item)" :item="item"></ArticleComponent>
            </template>
          </div>
        </div>
        <div class="wrapper px-5 mt-2">
          <div class="heading inne-h">
            <h2 @click="show[5] = !show[5];" class="cursor-pointer font-medium">Inne</h2>
          </div>

          <div class="cont" :class="(show[5]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(inne)" :key="'item_' + key">
              <ArticleComponent @click="selected(item);" :item="item"></ArticleComponent>
            </template>
          </div>
        </div>

      </div>
      <div class="col-span-9">
        <div class="sticky top-0">
            <div class="box info-window p-5 absolute z-50" v-if="showModal">
              <div class="close-btn absolute top-2 right-2 cursor-pointer" @click="showModal=false">
              </div>
              <div class="grid grid-cols-5">
                <div class="col-span-1 pr-4">
                  <div class="name">
                    {{ selectedMarker.name }}
                  </div>
<!--                  <div class="description font-light">-->
<!--                    {{ selectedMarker.description }}-->
<!--                  </div>-->
                </div>
                <div class="col-span-4">
                  <div class="gallery snap-x overflow-x-auto flex flex-center">
                    <div v-for="(item, index) in selectedMarker.images" :key="index" class="snap-center image flex-none" @click="showImg(index)">
                      <img :src="item" class="drop-shadow"/>
                    </div>
    <!--                <div class="snap-center image flex-none" v-if="selectedMarker.images[0] != undefined" @click="showImg(0)">-->
    <!--                  <img :src="selectedMarker.images[0]"/>-->
    <!--                </div>-->
    <!--                <div class="snap-center image flex-none" v-if="selectedMarker.images[1] != undefined" @click="showImg(1)">-->
    <!--                  <img :src="selectedMarker.images[1]"/>-->
    <!--                </div>-->
    <!--                <div class="snap-center image flex-none" v-if="selectedMarker.images[2] != undefined" @click="showImg(2)">-->
    <!--                  <img :src="selectedMarker.images[2]"/>-->
    <!--                </div>-->
    <!--                <div class="snap-center image flex-none" v-if="selectedMarker.images[3] != undefined" @click="showImg(3)">-->
    <!--                  <img :src="selectedMarker.images[3]"/>-->
    <!--                </div>-->
    <!--                <div class="snap-center image flex-none" v-if="selectedMarker.images[4] != undefined" @click="showImg(4)">-->
    <!--                  <img :src="selectedMarker.images[4]"/>-->
    <!--                </div>-->
    <!--                <div class="snap-center image flex-none" v-if="selectedMarker.images[5] != undefined" @click="showImg(5)">-->
    <!--                  <img :src="selectedMarker.images[5]"/>-->
    <!--                </div>-->
                  </div>
                </div>
              </div>
            </div>
            <div id="map">
              <l-map ref="mapRef" style="height:100vh"
                     v-model:center="center"
                     v-model:zoom="zoom"
                     v-if="!clearMe"
              >
                <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
                <l-marker @click="setMarker(item)" v-for="(item, key) in filterResults(parki)" :key="'mark_' + key" :lat-lng="item.coordinates" :name="item.name">
                  <l-icon :icon-url="'/icons/marker.png'" :i="JSON.stringify(item)"/>
                </l-marker>
                <l-marker @click="setMarker(item)" v-for="(item, key) in filterResults(szkoly)" :key="'mark_' + key" :lat-lng="item.coordinates" :name="item.name">
                  <l-icon :icon-url="'/icons/marker1.png'" :i="JSON.stringify(item)"/>
                </l-marker>
                <l-marker @click="setMarker(item)" v-for="(item, key) in filterResults(pomniki)" :key="'mark_' + key" :lat-lng="item.coordinates" :name="item.name">
                  <l-icon :icon-url="'/icons/marker2.png'" :i="JSON.stringify(item)"/>
                </l-marker>
                <l-marker @click="setMarker(item)" v-for="(item, key) in filterResults(honorowi)" :key="'mark_' + key" :lat-lng="item.coordinates" :name="item.name">
                  <l-icon :icon-url="'/icons/marker3.png'" :i="JSON.stringify(item)"/>
                </l-marker>
                <l-marker @click="setMarker(item)" v-for="(item, key) in filterResults(osiedla)" :key="'mark_' + key" :lat-lng="item.coordinates" :name="item.name">
                  <l-icon :icon-url="'/icons/marker4.png'" :i="JSON.stringify(item)"/>
                </l-marker>
                <l-marker @click="setMarker(item)" v-for="(item, key) in filterResults(inne)" :key="'mark_' + key" :lat-lng="item.coordinates" :name="item.name">
                  <l-icon :icon-url="'/icons/marker5.png'" :i="JSON.stringify(item)"/>
                </l-marker>
                <l-control-layers/>
              </l-map>
            </div>
        </div>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
      :visible="visible"
      :imgs="selectedMarker.images"
      :index="index"
      @hide="handleHide"
  ></vue-easy-lightbox>
</template>

<script>
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'
import VueEasyLightbox from 'vue-easy-lightbox'
import ArticleComponent from "@/components/ArticleComponent";
import "leaflet/dist/leaflet.css"
import {LMap, LTileLayer, LControlLayers, LMarker, LIcon} from "@vue-leaflet/vue-leaflet";
import {nextTick, onMounted, ref, watch} from "vue";
import UkraineComponent from "@/components/UkraineComponent";

export default {
  name: 'MainComponent',
  // eslint-disable-next-line vue/no-unused-components
  // methods: {
  //   zoomUpdated (zoom) {
  //     this.zoom = zoom;
  //     //console.log(zoom);
  //   },
  //   centerUpdated (center) {
  //     this.center = center;
  //     //console.log(center);
  //   }
  // },
  components: {UkraineComponent, ArticleComponent, LMap, LTileLayer, LControlLayers, LMarker, LIcon, VueEasyLightbox},
  setup() {
    const showUkr = ref(true);
    const visible = ref(false);
    const index = ref(0);
    const parki = require('../parki.json');
    const szkoly = require('../szkoly.json');
    const pomniki = require('../pomniki.json');
    const honorowi = require('../honorowi.json');
    const osiedla = require('../osiedla.json');
    const inne = require('../inne.json');
    const mapRef = ref(null);
    const clearMe = ref(false);
    const zoom = ref(9);
    const show = ref([false, false, false, false, false, false]);
    const showModal = ref(false);
    const images = ref([]);
    const center = ref([53.10672374130655, 18.36686924772039]);
    const search = ref('');
    const selectedMarker = ref({});
    // const siteMarkersGroup = new MarkerClusterGroup();
    //
    // mapRef.value.map.leafletObject.addLayer(siteMarkersGroup)
    // reactivate zoom at the desired location
    // [topleft, topright, bottomleft, bottomright]
    // L.control.zoom({ position: "topright" }).addTo(map);

    // holder for all articles
    const articles = document.querySelectorAll("article");

    watch(search, () => {
      clearMe.value = true
      if(search.value !== '') {
        show.value = [true, true, true, true, true, true]
      } else {
        show.value = [false, false, false, false, false, false]
      }
      setTimeout(() => {
        clearMe.value = false
      }, 200);
    })

    const setMarker =  async (r) => {
      selectedMarker.value = r;
      showModal.value = true;
      console.log(r);
    }

    const selected = async (i) => {
      showModal.value = false
      images.value = i.images;
      zoom.value = 16;
      const prevValue = center.value;
      center.value = i.coordinates;
      //await nextTick();
      // todo: temporary fix?
      if(prevValue === mapRef.value.center) {
        await new Promise(res => setTimeout(res, 200));
        zoom.value = 16;
        center.value = i.coordinates;
      }
    }

    const filterResults = (list) => {
      let tempAr = [];
      if (search.value === '') {
        return list;
      } else {
        list.forEach((val, key) => {
          if (val.name.toLowerCase().includes(search.value.toLowerCase())) {
            tempAr.push(val);
          }
        })
      }

      console.log('tempar', tempAr)

      return tempAr;
    }

    const showImg = (ind) => {
      index.value = ind;
      visible.value = true
    }

    const handleHide = () => {
      visible.value = false
    }

    onMounted(async () => {
        if(window.location.href.includes("/ukraina")) {
          // main.value = 'ukraina';
         showUkr.value = true;
        } else {
          showUkr.value = false;
        }

    })

    // // setting a marker
    // function setMarker([lat, lng], title) {
    //   const marker = mapRef.marker([lat, lng], { title });
    //   // add a marker to the map and create a popup
    //   marker.addTo(map).bindPopup(title);
    // }

    // map centering
    // function centerMap([lat, lng], target, title) {
    //   const active = target.classList.contains("active");
    //
    //   // set the map to lat coordinates, lng
    //   map.setView([lat, lng], 16);
    //
    //   // checking if the active class is already
    //   // set, if not, set the marker
    //   if (!active) {
    //     setMarker([lat, lng], title);
    //   }
    // }
    //
    // // helper function to intersectionObserver
    // function onChange(changes) {
    //   changes.forEach(function (change) {
    //     // get data from html coordinates element
    //     const data = change.target.dataset.coordinates;
    //     // get title from html
    //     const title = change.target.dataset.title;
    //
    //     if (change.intersectionRatio > 0) {
    //       // center map
    //       centerMap(JSON.parse(data), change.target, title);
    //       // add class to article
    //       change.target.classList.add("active");
    //     }
    //   });
    // }

    // checking if IntersectionObserver is supported
    // if ("IntersectionObserver" in window) {
    //   const config = {
    //     root: null,
    //     rootMargin: "0px",
    //     threshold: [0, 0.25, 0.5, 0.75, 1],
    //   };
    //
    //   let observer = new IntersectionObserver(onChange, config);
    //   articles.forEach(function (article) {
    //     observer.observe(article);
    //   });
    // }

    // this.siteMarkersGroup = new MarkerClusterGroup()
    // this.$refs.map.leafletObject.addLayer(this.siteMarkersGroup)
    // await this.$nextTick()
    // // `sites` is an array of objects with some coordinates.
    // this.siteMarkersGroup.addLayers(this.sites.map(s => {
    //   const options = { title: s.name, clickable: true, draggable: false }
    //   return L.marker(this.coordsToLatLng(s.coordinates), options).bindPopup(this.funcBuildingHTMLStringForPopup(s))
    // }))

    return {
      clearMe,
      showUkr,
      index,
      visible,
      showImg,
      handleHide,
      selectedMarker,
      selected,
      parki,
      szkoly,
      pomniki,
      honorowi,
      osiedla,
      inne,
      zoom,
      setMarker,
      articles,
      show,
      mapRef,
      center,
      filterResults,
      search,
      showModal
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vel-modal {
  z-index: 9999999998 !important;
}

</style>
