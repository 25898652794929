<template>
  <div class="single-coord px-2 py-2 cursor-pointer" :data-coordinates="item.coordinates" :data-title="item.name">

<!--    <small>ul. Agrykoli 1</small>-->
<!--    <img class="p-5"-->
<!--        :src="'/images/' + item.images[0]"-->
<!--        :alt="item.name">-->
    <span class="block ">{{item.name}}</span>
<!--    <p-->
<!--        class="font-light fade"-->
<!--        style="color: rgba(0,0,0,0.8)">{{truncate(item.description, 128, '...') }}</p>-->

  </div>
</template>

<script>
export default {
  name: "ArticleComponent",
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    truncate() {
        return (text, length, suffix) => {
          if (text.length > length) {
            return text.substring(0, length) + suffix;
          } else {
            return text;
          }
        }
    }
  }
}
</script>

<style scoped>

</style>
