<template>
  <div>
    <div class="grid grid-cols-12">
      <div class="col-span-3">
        <div class="search py-5 px-5">
          <input type="text" class="jp-input px-5 py-2" v-model="search" placeholder="Szukaj..."/>
        </div>
        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[0] = !show[0];" class="cursor-pointer">Powiat Aleksandrowski</h2>
          </div>
          <div class="cont" :class="(show[0]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(aleksandrowski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[1] = !show[1];" class="cursor-pointer">Powiat Brodnicki</h2>
          </div>
          <div class="cont" :class="(show[1]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(brodnicki)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[2] = !show[2];" class="cursor-pointer">Powiat Bydgoski</h2>
          </div>
          <div class="cont" :class="(show[2]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(bydgoski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[3] = !show[3];" class="cursor-pointer">Powiat Chełmiński</h2>
          </div>
          <div class="cont" :class="(show[3]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(chelminski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[4] = !show[4];" class="cursor-pointer">Powiat Golubsko-Dobrzyński</h2>
          </div>
          <div class="cont" :class="(show[4]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(golubsko)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[5] = !show[5];" class="cursor-pointer">Powiat Grudziądzki</h2>
          </div>
          <div class="cont" :class="(show[5]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(grudziadzki)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[6] = !show[6];" class="cursor-pointer">Powiat Inowrocławski</h2>
          </div>
          <div class="cont" :class="(show[6]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(inowroclawski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[7] = !show[7];" class="cursor-pointer">Powiat Lipnowski</h2>
          </div>
          <div class="cont" :class="(show[7]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(lipnowski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[8] = !show[8];" class="cursor-pointer">Powiat Mogileński</h2>
          </div>
          <div class="cont" :class="(show[8]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(mogilenski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[9] = !show[9];" class="cursor-pointer">Powiat Nakielski</h2>
          </div>
          <div class="cont" :class="(show[9]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(nakielski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[10] = !show[10];" class="cursor-pointer">Powiat Radziejowski</h2>
          </div>
          <div class="cont" :class="(show[10]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(radziejowski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[11] = !show[11];" class="cursor-pointer">Powiat Rypiński</h2>
          </div>
          <div class="cont" :class="(show[11]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(rypinski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[12] = !show[12];" class="cursor-pointer">Powiat Sępóleński</h2>
          </div>
          <div class="cont" :class="(show[12]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(sepolenski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[13] = !show[13];" class="cursor-pointer">Powiat Świecki</h2>
          </div>
          <div class="cont" :class="(show[13]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(swiecki)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[14] = !show[14];" class="cursor-pointer">Powiat Toruński</h2>
          </div>
          <div class="cont" :class="(show[14]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(torunski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[15] = !show[15];" class="cursor-pointer">Powiat Tucholski</h2>
          </div>
          <div class="cont" :class="(show[15]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(tucholski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[16] = !show[16];" class="cursor-pointer">Powiat Wąbrzeski</h2>
          </div>
          <div class="cont" :class="(show[16]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(wabrzeski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[17] = !show[17];" class="cursor-pointer">Powiat Włocławski</h2>
          </div>
          <div class="cont" :class="(show[17]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(wloclawski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

        <div class="wrapper px-5 py-2">
          <div class="heading schools-h">
            <h2 @click="show[18] = !show[18];" class="cursor-pointer">Powiat Żniński</h2>
          </div>
          <div class="cont" :class="(show[18]) ? '' : 'hidden'">
            <template v-for="(item, key) in filterResults(zninski)" :key="'item_' + key">
              <ArticleUkraineComponent @click="selected(item)" :item="item"></ArticleUkraineComponent>
            </template>
          </div>
        </div>

      </div>
      <div class="col-span-9 relative">

      </div>
    </div>
  </div>
</template>

<script>
import ArticleUkraineComponent from "@/components/ArticleUkraineComponent";
import "leaflet/dist/leaflet.css"
import {onMounted, ref} from "vue";
import DirectusCall from './DirectusCall.vue';

export default {
  name: 'UkraineComponent',
  components: {ArticleUkraineComponent},
  setup() {
    const selectedMarker = ref({});
    const showModal = ref(false);
    const search = ref('');
    const show = ref([true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]);
    const aleksandrowski = ref([]);
    const brodnicki = ref([]);
    const bydgoski = ref([]);
    const chelminski = ref([]);
    const golubsko = ref([]);
    const grudziadzki = ref([]);
    const inowroclawski = ref([]);
    const lipnowski = ref([]);
    const mogilenski = ref([]);
    const nakielski = ref([]);
    const radziejowski = ref([]);
    const rypinski = ref([]);
    const sepolenski = ref([]);
    const swiecki = ref([]);
    const torunski = ref([]);
    const tucholski = ref([]);
    const wabrzeski = ref([]);
    const wloclawski = ref([]);
    const zninski = ref([]);

    const filterResults = (list) => {
      let tempAr = ref([]);
      if (search.value === '') {
        return list;
      } else {
        list.forEach((val, key) => {
          if (val.name.toLowerCase().includes(search.value.toLowerCase())) {
            tempAr.value.push(val);
          }
        })
      }
      return tempAr.value;
    }

    const selected = (i) => {
      showModal.value = true;
      selectedMarker.value = i;
    }

    onMounted(() => {
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Aleksandrowsk%22%7D%7D]%7D',(response) => {
        aleksandrowski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Brodnicki%22%7D%7D]%7D',(response) => {
        brodnicki.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Bydgoski%22%7D%7D]%7D',(response) => {
        bydgoski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Chełmiński%22%7D%7D]%7D',(response) => {
        chelminski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Golubsko%22%7D%7D]%7D',(response) => {
        golubsko.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Grudz%22%7D%7D]%7D',(response) => {
        grudziadzki.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Inowr%22%7D%7D]%7D',(response) => {
        inowroclawski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Lipnowski%22%7D%7D]%7D',(response) => {
        lipnowski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Mogil%22%7D%7D]%7D',(response) => {
        mogilenski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Nakiel%22%7D%7D]%7D',(response) => {
        nakielski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Radziej%22%7D%7D]%7D',(response) => {
        radziejowski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Rypi%22%7D%7D]%7D',(response) => {
        rypinski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Sępo%22%7D%7D]%7D',(response) => {
        sepolenski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Świec%22%7D%7D]%7D',(response) => {
        swiecki.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Toru%22%7D%7D]%7D',(response) => {
        torunski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Tuch%22%7D%7D]%7D',(response) => {
        tucholski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Wąbrze%22%7D%7D]%7D',(response) => {
        wabrzeski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Włoc%22%7D%7D]%7D',(response) => {
        wloclawski.value = response.data;
      });
      DirectusCall('/items/coordiator?access_token=aaaa&limit=25&fields[]=description&fields[]=name&fields[]=powiat&fields[]=id&sort[]=id&page=1&filter=%7B%22_and%22:[%7B%22powiat%22:%7B%22_contains%22:%22Żnin%22%7D%7D]%7D',(response) => {
        zninski.value = response.data;
      });
    });

    return {
      selected,
      show,
      filterResults,
      aleksandrowski,
      brodnicki,
      bydgoski,
      chelminski,
      golubsko,
      grudziadzki,
      inowroclawski,
      lipnowski,
      mogilenski,
      nakielski,
      radziejowski,
      rypinski,
      sepolenski,
      swiecki,
      torunski,
      tucholski,
      wabrzeski,
      wloclawski,
      zninski,
      search
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
