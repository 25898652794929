<template>
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
  <MainComponent></MainComponent>
</template>

<script>
// import MainComponent from './components/MainComponent'
import UkrainComponent from './components/MainComponent'
import {onMounted, ref} from "vue";
import SubMain from "@/SubMain";
import MainComponent from "@/components/MainComponent";

export default {
  name: 'App',
  components: {
    MainComponent,
    // SubMain
  },
  setup() {

  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.info-window .gallery .image img {
  max-width: 300px;
}
</style>
