<template>
  <div class="single-coord px-2 py-2 cursor-pointer" :data-title="item.name" v-if="item != null">

<!--    <small>ul. Agrykoli 1</small>-->
<!--    <img class="p-5"-->
<!--        :src="'/images/' + item.images[0]"-->
<!--        :alt="item.name">-->
    <span class="block"><strong>{{item.name}}</strong></span>
    <p class="">{{item.description}}</p>

  </div>
</template>

<script>
export default {
  name: "ArticleUkraineComponent",
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>